/* @import url(https://rsms.me/inter/inter.css);*/

/* Thin */
/* Thin Italic */

/* Ultralight */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-UltraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

/* Ultralight Italic*/
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-UltraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

/* Light Italic */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

/* Regular Italic*/
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

/* Medium */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

/* Medium Italic*/
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

/* Semibold */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-DemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

/* Semibold Italic*/
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-DemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

/* Bold */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

/* Bold Italic */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

/* Extra Bold */
/* Extra Bold Italic */

/* Heavy */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-Heavy.ttf');
  font-weight: 900;
  font-style: normal;
}

/* Heavy Italic */
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNext-HeavyItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

:root {
  --tw-blue-500: #2563eb;
  --tw-indigo-100: #e0e7ff;
  --tw-gray-200: rgb(229 231 235);
  --tw-gray-300: rgb(209 213 219);
}

html,
body {
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

.fc th {
  border-style: none !important;
  border-width: 1px !important;
  padding: 0 !important;
  vertical-align: top !important;
}

.fc .fc-timegrid-slot-minor {
  border-top: none !important;
}

/* Remove calendar day yellow tint highlight */
.fc-day-today {
  background: inherit !important;
  border: none !important;
}

.fc-scrollgrid-section {
  font-weight: 300 !important;
  font-size: medium !important;
}

.fc-event-main p {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Remove calendar outer border */
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

/* Remove calendar event white border */
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

/* Calendar back and next button styles */
.fc .fc-button-group .fc-button.fc-prev-button,
.fc .fc-button-group .fc-button.fc-next-button {
  padding: 0.3rem !important;
  background-color: var(--tw-indigo-100);
  color: var(--tw-blue-500);
  border: none;
  border-radius: 9px;
  margin-left: 0;
}
.fc .fc-button-group,
.fc .fc-button {
  margin-left: 0 !important;
}

.fc .fc-button-group .fc-button:hover {
  color: var(--tw-blue-500);
  background-color: #eef2ff;
}

/* Update the color of the focus ring for all buttons */
.fc .fc-button:focus {
  box-shadow: none !important;
  outline: 3px solid var(--tw-blue-500) !important;
}

.fc .fc-daygrid-week-number {
  padding: 0px !important;
  background-color: transparent !important;
}

/* Calendar highlight color */
.fc-highlight {
  background-color: #e1e5e96c !important;
}

/* More events popover */
.fc .fc-popover .fc-popover-header {
  background: var(--tw-gray-200);
  padding: 0.5rem 0.8rem;
}

.fc .fc-popover .fc-popover-body {
  background: white;
}
.fc .fc-popover .fc-popover-body .fc-timegrid-event {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 3px;
}

.fc .fc-popover {
  z-index: 49 !important;
}

.striped-gray {
  background: repeating-linear-gradient(
    45deg,
    var(--tw-gray-200),
    var(--tw-gray-200) 3px,
    var(--tw-gray-300) 3px,
    var(--tw-gray-300) 6px
  );
}

.hide-scrollbar *::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* classes used for ipad */
.touchscreen-only {
  display: none;
}

.ipad-overflow-scrolling {
  -webkit-overflow-scrolling: auto;
}

.ipad-sticky {
  position: -webkit-sticky;
}

@media (pointer: coarse) and (hover: none) {
  .touchscreen-only {
    display: block;
  }

  input[type='file']::-webkit-file-upload-button {
    border: none;
    margin-top: 0.6rem;
    padding: 0;
    -webkit-appearance: none;
    width: 0;
  }

  x::-webkit-file-upload-button,
  input[type='file']::before {
    content: 'Datei auswählen';
    font-weight: 500;
    display: inline-block;
    border-radius: 6px;
    color: white;
    padding: 0.6rem 1rem;
    position: relative;
    background-color: var(--tw-blue-500);
  }
}

@media only screen and (max-width: 640px) {
  /* reduce width of first column in the calendar */
  .fc colgroup > col,
  .fc .fc-timegrid-axis-cushion {
    width: 45px !important;
  }

  .fc .fc-toolbar-title {
    font-size: 1.2em !important;
  }
}

#launcher-frame {
  z-index: 49 !important;
  bottom: 8px !important;
  right: 10px !important;
}

.chartjs-tooltip-body {
  font-weight: normal;
  font-size: 0.8em;
}
